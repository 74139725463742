@import "@/assets/scss/style.scss";

.pdf-preview__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .single-pdf-preview {
        // centre
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .pdf-title {
            margin-bottom: 10px;
        }

        .pdf-checkmark {
            font-size: 40px;
            color: $roltek-blue;
        }

        .pdf-checkmark--grey {
            color: $grey-7;
        }

        .pdf-span {
            font-size: 20px;
            // font-weight: bold;
            // border: 2px solid $grey-8;
            // padding: 2px;
            color: $roltek-blue;
            cursor: pointer;
        }

        .pdf-disabled {
            color: $grey-7;
            cursor: not-allowed;
        }
    }

    .pdf-arrow {
        font-size: 18px;
        color: $grey-8;
        margin: 2px 10px 0 20px;
    }
}
