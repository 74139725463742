@import "@/assets/scss/style.scss";

// Prettify labels and values in this component
.general-info__date-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
}
.date-wrapper__label {
    font-weight: bold;
    color: $grey-9;
    margin-right: 5px;
}
.date-wrapper__value {
    color: $grey-8;
}
