@import "@/assets/scss/style.scss";

.order-form-popup {
    &__content {
        height: 70vh;
    }
}

::v-deep(.ant-modal) {
    width: 40vw !important;

    @media (max-width: 1400px) {
        width: 50vw !important;
    }

    @media (max-width: 1200px) {
        width: 60vw !important;
    }

    @media (max-width: 768px) {
        width: 80vw !important;
    }

    @media (max-width: 480px) {
        width: 95vw !important;
    }
}
