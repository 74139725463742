@import "@/assets/scss/style.scss";

.wrapperProjectTabOrder {
    & > * {
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;

            @media screen and (max-width: 650px) {
                padding: 20px 10px;
            }
        }
    }
}
